/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 /*jslint eqeq: true*/

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        if($('.sidebar-carousel').length) {
          $('.sidebar-carousel').slick({
            dots: true,
            infinite: true,
            speed: 1240,
            fade: true,
            arrows: false,
            cssEase: 'ease-in-out',
            autoplay: true,
            autoplaySpeed: 2000
          });
        }

        // toggles hamburger and nav open and closed states
        var removeClass = true;
          $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        $("html").click(function () {
          if (removeClass) {
            $(".hamburger").removeClass('is-active');
            $("#sideNav").removeClass('sideNav-open');
            $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        });

        // swipe to close
        var lastScrollLeft = 0;
        $(window).scroll(function() {
          var documentScrollLeft = $(document).scrollLeft();
          if (lastScrollLeft != documentScrollLeft) {
            if (removeClass) {
              $(".hamburger").removeClass('is-active');
              $(".sideNav").removeClass('sideNav-open');
              $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
            lastScrollLeft = documentScrollLeft;
          }
        });

        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        document.initMap = function() {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          var isDraggable = w > 480 ? true : false;
          // Create the map.
          var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 6,
            scrollwheel: false,
            draggable: isDraggable,
            center: {lat: 44.8012, lng: -68.7778},
            mapTypeId: google.maps.MapTypeId.CITY
          });

          // Construct the circle for each value in citymap.
          // Note: We scale the area of the circle based on the population.

          // Add the circle for this city to the map.
          var bangorCircle = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: map,
            center: {lat: 44.8012, lng: -68.7778},
            radius: 160934
          });

          var center;
          google.maps.event.addDomListener(map, 'idle', function() {
            center = map.getCenter();
          });
          google.maps.event.addDomListener(window, 'resize', function() {
            map.setCenter(center);
          });
        };

        // if($('#map').length) {
        //   var maps_api_key = 'AIzaSyCmLb-0xCnQENYVQitmbeJkT3fsnRaqaOE';
        //   var maps_callback = 'document.initMap';
        //   var maps_api_url = 'https://maps.google.com/maps/api/js';
        //   var maps_script_tag = document.createElement('script');
        //   maps_script_tag.setAttribute('type', 'text/javascript');
        //   maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        //   maps_script_tag.setAttribute('defer', '');
        //   maps_script_tag.setAttribute('async', '');
        //   document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
        // }

        $('.service-btn').click(function(){
          var service_id = $(this).data('target');
          if ($('.content').is(':animated')) {
            return;
          }

          if(service_id == '#residential') {
            if($('.commercial-btn').hasClass('active')) {
              $('.commercial-btn').removeClass('active');
            }
            if(!$('.residential-btn').hasClass('active')) {
              $('.residential-btn').addClass('active');
            }
            $('.select-service').find('.commercial').fadeOut(800);
            $('.select-service').find('.residential').fadeIn(700);
            $('.pavement-service').find('.content:not(' + service_id + ')').fadeOut(500, function(){
              if($(this).hasClass('active')) {
                $(this).removeClass('active');
              }
            });
            $(service_id).fadeIn(600);
            $(service_id).addClass('active');

          } else if(service_id == '#commercial') {
            if($('.residential-btn').hasClass('active')) {
              $('.residential-btn').removeClass('active');
            }
            if(!$('.commercial-btn').hasClass('active')) {
              $('.commercial-btn').addClass('active');
            }
            $('.select-service').find('.residential').fadeOut(800);
            $('.select-service').find('.commercial').fadeIn(700);
            $('.pavement-service').find('.content:not(' + service_id + ')').fadeOut(500, function(){
              if($(this).hasClass('active')) {
                $(this).removeClass('active');
              }
            });
            $(service_id).fadeIn(600);
            $(service_id).addClass('active');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'project_gallery': {
      init: function() {
        $('#residential-gallery').lightGallery({
          thumbnail: true,
          animateThumb: true,
          download: false
        });

        $('#commercial-gallery').lightGallery({
          thumbnail: true,
          animateThumb: true,
          download: false
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the conatct page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
